import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import arrowRight from 'Assets/LessonPlanModule/arrowRight.svg';
import crossMark from 'Assets/AttendanceModule/crossMark.svg';
import loaderImag from 'Assets/ProfileModule/PepperyMedium.gif'
import { createNotification } from 'Config/notificationtoast';
import Planning from './Planning';
import Execution from './Execution';
import Evaluation from './Evaluation';
import {
	addLessonPlan,
	getViewQuestionList,
	viewLessonPlanDetails,
	saveExecutionLessonPlan,
	saveEvaluationLessonPlan,
	deleteDynamicQuestionLessonPlan

} from 'Actions/ClassAction/ClassLessonPlanAction';
import './AddNewLessonPlan.scss';
import Col from 'react-bootstrap/Col';
import back from 'Assets/CommonComponent/back.png';
import Modal from 'react-bootstrap/Modal';
import warningIcon from 'Assets/NoteModule/warning-icon.png'

const AddNewLessonPlan = (props) => {
	const {
		addLessonPlan,
		questionsList,
		saveExecutionLessonPlan,
		getViewQuestionList,
		viewLessonPlanDetailsState,
		viewLessonPlanDetails,
		deleteDynamicQuestionLessonPlanState,
		saveEvaluationLessonPlan,
		deleteDynamicQuestionLessonPlan,
		history,
		state,
		stateselectionType,
	} = props;
	const [selectedTab, setSelectedTab] = useState("Planning");
	const [asDefault, setAsDefault] = useState(false);
	const [saveData, setSaveData] = useState("");
	const [apiDatasPlanning, setApiDatasPlanning] = useState({});
	const [apiDatasExecution, setApiDatasExecution] = useState({});
	const [apiDatasEvaluation, setApiDatasEvaluation] = useState({});
	const [apiCallPlanning, setApicallPlanning] = useState(true);
	const [evalSubmitFade, setEvalSubmitFade] = useState(false);
	const [classBreadcrumb, setClassBreadcrumb] = useState(false)
	const [homeBreadcrumb, setHomeBreadcrumb] = useState(false)
	const [goBackModal, setGoBackModal] = useState(false)
	
	 const confirmSlectDiv = ["confirmSlectDiv", "title" in apiDatasPlanning === false ? "opacityFade" : apiDatasPlanning?.title?.trim() === "" ||viewLessonPlanDetails?.data?.cc_lp_title?.trim() === "" ? "opacityFade" : apiDatasPlanning?.topic?.trim() === "" || viewLessonPlanDetails?.data?.cc_lp_topic?.trim() === "" ? "opacityFade" : ""]
	const confirmSlectDivExsave = ["confirmSlectDiv", "title" in apiDatasPlanning === false ? "opacityFade" : apiDatasPlanning?.title?.trim() === "" &&viewLessonPlanDetails?.data?.cc_lp_title?.trim() === "" ? "opacityFade" : apiDatasPlanning?.topic?.trim() === "" && viewLessonPlanDetails?.data?.cc_lp_topic?.trim() === "" ? "opacityFade" : ""]
	const confirmSlectDivEx = ["confirmSlectDiv addNewLesson BR-8", "title" in apiDatasPlanning === false ? "opacityFade addNewLessonFadeAlignItems addNewLessonFade" : apiDatasPlanning?.title?.trim() === "" ? "opacityFade addNewLessonFadeAlignItems addNewLessonFade" : apiDatasPlanning?.topic?.trim() === "" ? "opacityFade addNewLessonFadeAlignItems addNewLessonFade" : ""]
	const confirmEvalSlectDiv = ["confirmSlectDiv", evalSubmitFade === false ? "opacityFade" : ""]
	const { ClassDetails,
		selectionType,
		lessonPlanId,
		academicYearId,
		academicYear,
		classId,
		BackPage,
		classPermission } = history.location.state;
		
	const { loader } = state.classLessonPlan;
	
   const state11 = useSelector((state) => state)
   console.log('state11 :>> ', state11);
   console.log('confirmSlectDiv :>> ', confirmSlectDiv);
	useEffect(() => {
		if (selectionType === "edit" ) {
			
			const apiData = {
				"class_id": ClassDetails?.cc_class_id || classId,
				"lesson_plan_id": lessonPlanId || props?.addLessonPlanList?.data?.lesson_plan_id,
			}
			viewLessonPlanDetails(apiData)
		}
	}, [selectionType])
	useEffect(() => {

		if (selectedTab === "Execution"){
		const apiData = {
			"class_id": ClassDetails?.cc_class_id || sessionStorage.getItem("Class_ID_for_LP"),
			"lesson_plan_id": lessonPlanId || props?.addLessonPlanList?.data?.lesson_plan_id,
		}

	   
	}
	   }
	   , [])

	const [settitlepl, setsettitlepl] = useState()
	const [settopicpl, setsettopicpl] = useState()
	const [statequestion_answer_array , setstatequestion_answer_array] = useState([])
	const [planned_at , setplanned_at] = useState()
	const sendtitle = (index) => { 
		
		setsettitlepl(index);
	  };
	  const sendtopic = (index) => { 
		
		setsettopicpl(index);
	  };
	  const sendquestionanswerobject = (index) => { 
		
		setstatequestion_answer_array(index);
	  };
	  const sendplannedat = (index) => { 
		
		setplanned_at(index);
	  };
	useEffect(() => {
		if (deleteDynamicQuestionLessonPlanState && deleteDynamicQuestionLessonPlanState.message === "Deleted successfully") {
			const apiData = {
				"class_id": ClassDetails?.cc_class_id || classId,
				"lesson_plan_id": lessonPlanId || props?.addLessonPlanList?.data?.lesson_plan_id,
			}
			viewLessonPlanDetails(apiData)
		}
	}, [deleteDynamicQuestionLessonPlanState])

	useEffect(() => {
		if (selectionType === "addNew") {
			getViewQuestionList();
		}
	}, [])

	const BackTOHome = () => {
		window.sessionStorage.removeItem('selected_class_name')
		history.goBack();
	}
	const BackToDash = () => {
        history.push("/home/academic")
    }
	// const changeTab = () => {
	// 	if (selectedTab === "Planning") {
	// 		setSelectedTab("Execution");
	// 	}
	// 	if (selectedTab === "Execution") {
	// 		setSelectedTab("Analysis & Evaluation");
	// 	}
	// }
   const saveNewLessonPlanEx = () => {
	sessionStorage.setItem("CID",ClassDetails?.cc_class_id || classId)
	if (selectedTab === "Planning") {
		if (apiDatasPlanning.topic === "" || apiDatasPlanning.title === "" || (sessionStorage.getItem('LPTitle')?.length == 0)) {
			if (apiDatasPlanning.topic === "") {
				createNotification('warning', 'Please Enter Topic');
			}
			else if (apiDatasPlanning.title === "") {
				createNotification('warning', 'Please Enter Title');
			}
		} else {
			addLessonPlan(apiDatasPlanning);
			// history.goBack();
		}
	}
	if (selectedTab === "Execution") {
		saveExecutionLessonPlan(apiDatasExecution);
		// history.goBack();
	}
	if (selectedTab === "Evaluation") {
		saveEvaluationLessonPlan(apiDatasEvaluation);
		setTimeout(() => {
			history.goBack();
		}, 1000);
	}
	
	setSelectedTab("Execution")
	// sessionStorage.setItem("LID", props?.addLessonPlanList?.data?.lesson_plan_id)
	
	

   }

   const saveNewLessonPlanEv = () => {
	// alert("dasd")
	sessionStorage.setItem("CID",ClassDetails?.cc_class_id || classId)
	if (selectedTab === "Planning") {
		if (apiDatasPlanning.topic === "" || apiDatasPlanning.title === "") {
			if (apiDatasPlanning.topic === "") {
				createNotification('warning', 'Please Enter Topic');
			}
			else if (apiDatasPlanning.title === "") {
				createNotification('warning', 'Please Enter Title');
			}
		} else {
			addLessonPlan(apiDatasPlanning);
			// history.goBack();
		}
	}
	if (selectedTab === "Execution") {
		saveExecutionLessonPlan(apiDatasExecution);
		// history.goBack();
	}
	if (selectedTab === "Evaluation") {
		saveEvaluationLessonPlan(apiDatasEvaluation);
		setTimeout(() => {
			history.goBack();
		}, 1000);
	}
	
	setSelectedTab("Evaluation")
	// sessionStorage.setItem("LID", props?.addLessonPlanList?.data?.lesson_plan_id)

	

   }
	const saveNewLessonPlan = () => {
		// const apiData = {
		// 	"class_id": ClassDetails?.cc_class_id || classId,
		// 	"lesson_plan_id": lessonPlanId || props?.addLessonPlanList?.data?.lesson_plan_id,
		// }
		// viewLessonPlanDetails(apiData)

		if (selectedTab === "Planning") {
			if (apiDatasPlanning.topic === "" || apiDatasPlanning.title === "" || sessionStorage.getItem("addLessonPlan")?.length > 0) {
				if (apiDatasPlanning.topic === "") {
					createNotification('warning', 'Please Enter Topic');
				}
				else if (apiDatasPlanning.title === "") {
					createNotification('warning', 'Please Enter Title');
				}
			} else {
				addLessonPlan(apiDatasPlanning);
				history.goBack();
			}
		}
		if (selectedTab === "Execution") {
			saveExecutionLessonPlan(apiDatasExecution);
			history.goBack();
		}
		if (selectedTab === "Evaluation") {
			saveEvaluationLessonPlan(apiDatasEvaluation);
			setTimeout(() => {
				history.goBack();
			}, 1000);
		}
	}

	const unSavetWhenClose = () => {
		if (selectedTab === "Planning") {
			setApicallPlanning(false);
		}
		if (selectedTab === "Execution") {
			setApicallPlanning(false);
		}
		if (selectedTab === "Evaluation") {
			setApicallPlanning(false);
		}
	}

	const selecTabClick = (val) => {
	   console.log('viewLessonPlanDetailsState?.data :>> ', viewLessonPlanDetailsState);
		if (selectionType === "edit" || selectionType === "addNew") {

			const { cc_lp_status } = viewLessonPlanDetailsState?.data || {};
			if (cc_lp_status === "planning" && val === "Execution") {
				setSelectedTab(val);
			} else if (cc_lp_status === "execution" && val === "Execution") {
				setSelectedTab(val);
			} else if (cc_lp_status === "execution" && val === "Evaluation") {
				setSelectedTab(val);
			} else if (cc_lp_status === "evaluation" && val === "Evaluation" ) {
				setSelectedTab(val);
			} else if (cc_lp_status === "evaluation" || "execution" && val === "Planning") {
				setSelectedTab(val);
			}
		}
		// else if(selectionType === "addNew") {
		// 	// Object.keys(viewLessonPlanDetailsState?.data).forEach(key => (viewLessonPlanDetailsState?.data)[key]= null);
		// 	console.log(viewLessonPlanDetailsState?.data , "==viewLessonPlanDetailsState")
		// 	const { cc_lp_status } = viewLessonPlanDetailsState.data;
		// 	console.log("cc_lp_status",cc_lp_status)
		// 	if (cc_lp_status === "planning" && val === "Execution" ) {
		// 		setSelectedTab(val);
		// 	} else if (cc_lp_status === "execution" && val === "Execution" ) {
		// 		setSelectedTab(val);
		// 	} else if (cc_lp_status === "execution" && val === "Evaluation" ) {
		// 		setSelectedTab(val);
		// 	} else if (cc_lp_status === "evaluation" && val === "Evaluation" ) {
		// 		setSelectedTab(val);
		// 	} else if (cc_lp_status === "evaluation" || "execution" && val === "Planning") {
		// 		setSelectedTab(val);
		// 	}
		// 	if (cc_lp_status == "planning") {
		// 		setSelectedTab("Planning")
		// 	}
		// }
		//  else {
		// 	setSelectedTab("Planning");
		// }
	}
	const goBCKandEmptyLS = () => {
        window.sessionStorage.removeItem('selected_class_name')
        history.push("/home/academic")
    }
	return (
		<div className="addNewLessonContainer">
			<div className='addLessonPlanMainDiv'>
				{/* <div className='LessonPlanDetailsMain'> */}
					{/* <div className='ClassBackDiv' onClick={BackTOHome}>
						<i className='lessonBackIcon material-icons'>chevron_left</i>
						<p className='lessonClassBactText'>Lesson Plan</p>
					</div>
					<div className='attClassDetailTextDiv'>
						<p className='head'> {ClassDetails.cc_class_name}</p>
						<div className='displayFlexClassRoster'>
							<p className='attClassGrade'>Total Students: {ClassDetails.student_count}</p>
						</div>
					</div> */}
				{/* </div> */}

                    <Col xs="5" className=" note-header-title " style={{    display: "-webkit-box", marginLeft: "0px"}}>
                            <div className='stdNoteBackDiv'>
                                <img className='calIcon' src={back} alt='' width='60' height='40' onClick={() => {
									if(apiDatasPlanning?.title?.trim().length > 0 || apiDatasPlanning?.topic?.trim().length > 0) {
										console.log('popup')
										setGoBackModal(true)
										setHomeBreadcrumb(true)
									} else {
										console.log('back')
										BackToDash()
										sessionStorage.removeItem('LPPlaiingQuestion')
										sessionStorage.removeItem('LPAudioList')
										sessionStorage.removeItem('LPTopic')
										sessionStorage.removeItem('LPTitle')
										sessionStorage.removeItem('LPUrllist')
										sessionStorage.removeItem('LPReminderTime')
										sessionStorage.removeItem('LPNotification')
										sessionStorage.removeItem('LPFormatDate')
										sessionStorage.removeItem('LPDocList')
										sessionStorage.removeItem('LPMinutes')
										sessionStorage.removeItem('LPPictureVideoList') 
									}
								}} />
                            </div>
                            <div className='stdNoteDetailTextDiv'>
                                <div className='stdNoteDisplayFlex stdNoteDisplayFlex-p0'>
                                  
								<p className='currentYear' onClick={() => {
									if(apiDatasPlanning?.title?.trim().length > 0 || apiDatasPlanning?.topic?.trim().length > 0) {
										console.log('popup')
										setGoBackModal(true)
										setClassBreadcrumb(true)
									} else {
										console.log('back')
										window.history.go(-2)
										sessionStorage.removeItem('LPPlaiingQuestion')
										sessionStorage.removeItem('LPAudioList')
										sessionStorage.removeItem('LPTopic')
										sessionStorage.removeItem('LPTitle')
										sessionStorage.removeItem('LPUrllist')
										sessionStorage.removeItem('LPReminderTime')
										sessionStorage.removeItem('LPNotification')
										sessionStorage.removeItem('LPFormatDate')
										sessionStorage.removeItem('LPDocList')
										sessionStorage.removeItem('LPMinutes')
										sessionStorage.removeItem('LPPictureVideoList') 
									}
									
									}}
									style={{padding : 0}}
									><b>{history.location.state.BackPage == 'LP' ? history.location.state.academicYear : 'Class'}</b></p>

                                    <i style={"Class Roster" == "Class Roster" ? {display : "block"} : {display : "none"}} className="classForIcon material-icons">chevron_right</i>
                                    
                                    <p className='stdNoteGrade1' onClick={() => {
										// BackTOHome
										if(apiDatasPlanning.title.length > 0 || apiDatasPlanning.topic.length > 0) {
											console.log('popup')
											setGoBackModal(true)
										} else {
											console.log('back')
											BackTOHome()
										}
										}}
										style={{padding : 0}}
										>Lesson Plan</p>
                                    <i className="classForIcon material-icons">chevron_right</i>
                                    
                                    <p className='stdNoteGrade2'>Create Plan: {sessionStorage.getItem('selected_class_name') ? sessionStorage.getItem('selected_class_name').replace(/(.{13})..+/, "$1…") : ClassDetails?.cc_class_name?.replace(/(.{13})..+/, "$1…")}</p>
                                </div>
                            </div>
                        </Col>
				<div className='displayFlexInSeletAtt'>
					<div className='undoSelect'
						onClick={() => {history.goBack();
							sessionStorage.removeItem('LPPlaiingQuestion')
							sessionStorage.removeItem('LPAudioList')
							sessionStorage.removeItem('LPTopic')
							sessionStorage.removeItem('LPTitle')
							sessionStorage.removeItem('LPUrllist')
							sessionStorage.removeItem('LPReminderTime')
							sessionStorage.removeItem('LPNotification')
							sessionStorage.removeItem('LPFormatDate')
							sessionStorage.removeItem('LPDocList')
							sessionStorage.removeItem('LPMinutes')
							sessionStorage.removeItem('LPPictureVideoList') 
						}}
						style = {{paddingRight : "1px"}}
					>
						<img
							src={crossMark}
							className='closeIcon'
							alt=''
							width='20'
							height='20'
							style={{marginRight : 1}}
						/>
						Cancel

					</div>
					{selectedTab === "Planning" && 
					<div 
					className={confirmSlectDiv.join(' ')}
					// className='confirmSlectDiv'
						onClick={() => {if(props?.history?.location?.state?.classPermission == 'read') { 
							return;
						} else { 
							saveNewLessonPlan()
						}}}
						style = {{justifyContent: "center", alignItems : "center", color: "white",  width: "100px",	height: "40px" , display : "flex", borderRadius : "8px", opacity : props?.history?.location?.state?.classPermission == 'read' && "0.5", cursor : props?.history?.location?.state?.classPermission == 'read' && 'default'}}
					>   
						<i className='confirmIconsLesson material-icons'>

							check
						</i>
						Save
					</div>}
					{selectedTab === "Execution" && <div className={confirmSlectDivExsave.join(' ')}
						onClick={() => {
							if(props?.history?.location?.state?.classPermission == 'read') {
								return;
							} else {
								saveNewLessonPlan()
							}
						}} style = {{justifyContent: "center", alignItems : "center", color: "white",  width: "100px",	height: "40px" , display : "flex", borderRadius : "8px", opacity : props?.history?.location?.state?.classPermission == 'read' && "0.5", cursor : props?.history?.location?.state?.classPermission == 'read' && 'default'}}>
						<i className='confirmIconsLesson material-icons'>check</i> Save
					</div>}
					{selectedTab === "Evaluation" && <div className={confirmEvalSlectDiv.join(' ')} onClick={() => {
						if(props?.history?.location?.state?.classPermission == 'read') {
							return;
						} else {
							saveNewLessonPlan()
						}
					}}
					style = {{justifyContent: "center", alignItems : "center", color: "white",  width: "100px",	height: "40px" , display : "flex", borderRadius : "8px", opacity : props?.history?.location?.state?.classPermission == 'read' && "0.5", cursor : props?.history?.location?.state?.classPermission == 'read' && 'default'}}>
						<i className='confirmIconsLesson material-icons'>check</i>
						Save
					</div>}
				</div>
				{selectionType === "addNew" ? selectedTab === "Planning" && <div className='buttonMarginTop'>
					<Button className={confirmSlectDivEx.join(' ')} onClick={saveNewLessonPlanEx}>
						<img className='lessonIcons' src={arrowRight} alt='' />
						<span className='viewAttText'>Go for Execution</span>
					</Button>
				</div> : ""}
				{selectionType === "edit" && selectedTab === "Planning" && <div className='buttonMarginTop'>
					<Button className='addNewLesson BR-8'
						onClick={() => selecTabClick("Execution")}
						disabled={props?.history?.location?.state?.classPermission == 'read'}
					>
						<img className='lessonIcons' src={arrowRight} alt='' />
						<span className='viewAttText'>Go for Execution</span>
					</Button>
				</div>}
			
				{selectedTab === "Execution" && viewLessonPlanDetailsState && viewLessonPlanDetailsState.data.cc_lp_status === "execution" ? <div className='buttonMarginTop'>
					<Button className='addNewLesson BR-8'
						onClick={saveNewLessonPlanEv}
						disabled={props?.history?.location?.state?.classPermission == 'read'}
					>
						<img className='lessonIcons' src={arrowRight} alt='' />
						<span className='viewAttText'>Go for Evaluation</span>
					</Button>
				</div> : ""}
				{selectedTab === "Execution" && viewLessonPlanDetailsState && viewLessonPlanDetailsState.data.cc_lp_status === "planning" ? <div className='buttonMarginTop'>
					<Button className='addNewLesson BR-8' onClick={saveNewLessonPlanEv} disabled={props?.history?.location?.state?.classPermission == 'read'}>
						<img className='lessonIcons' src={arrowRight} alt='' />
						<span className='viewAttText'>Go for Evaluation</span>
					</Button>
				</div> : ""}
			</div>
			{loader ?
				(<div>
					<img src={loaderImag} alt='' className='loaderIconCss' />
				</div>) :
				(<div className="tabsDiv">
					<Tabs
					    
						id="controlled-tab-example"
						activeKey={selectedTab}
						onSelect={(k) => selecTabClick(k)}
						className="mb-3 abc"
					>
						<Tab eventKey="Planning" title="Planning">
						
							<Planning
								saveData={saveData}
								questionsList={questionsList}
								apiDatas={apiDatasPlanning}
								setApiDatas={setApiDatasPlanning}
								ClassDetails={ClassDetails}
								setSaveData={setSaveData}
								asDefault={asDefault}
								apiCallPlanning={apiCallPlanning}
								setApicallPlanning={apiCallPlanning}
								history={history}
								viewLessonPlanDetailsState={viewLessonPlanDetailsState}
								selectionType={selectionType}
								lessonPlanId={lessonPlanId}
								academicYearId={academicYearId}
								academicYear={academicYear}
								classId={classId || ClassDetails?.cc_class_id}
								classPermission={classPermission}
								selectedTab={selectedTab}
								lesson_plan_id = {props?.addLessonPlanList?.data?.lesson_plan_id}
								titlepl = {settitlepl}
								topicpl = {settopicpl}
								statequestion_answer_array = {statequestion_answer_array}
								pplanned_at = {planned_at}
							/>
						</Tab>

						<Tab eventKey="Execution" title="Execution">
						
							<Execution
								asDefault={asDefault}
								saveData={saveData}
								setApiDatas={setApiDatasPlanning}
								viewLessonPlanDetailsState={viewLessonPlanDetailsState}
								questionsList={questionsList}
								history={history}
								setSaveData={setSaveData}
								lessonPlanId={lessonPlanId}
								apiCallPlanning={apiCallPlanning}
								ClassDetails={ClassDetails}
								selectionType={selectionType}
								saveExecutionLessonPlan={saveExecutionLessonPlan}
								apiDatasExecution={apiDatasExecution}
								setApiDatasExecution={setApiDatasExecution}
								selectedTab={selectedTab}
								lesson_plan_id = {props?.addLessonPlanList?.data?.lesson_plan_id}
								questions_list = {props?.questionsList}
								sendtitle = {sendtitle}
								sendtopic = {sendtopic}
								sendquestionanswerobject = {sendquestionanswerobject}
								sendplannedat = {sendplannedat}
								
							/>
						</Tab>
						<Tab eventKey="Evaluation" title="Analysis & Evaluation">
							<Evaluation
								asDefault={asDefault}
								history={history}
								viewLessonPlanDetailsState={viewLessonPlanDetailsState}
								saveData={saveData}
								selectionType={selectionType}
								apiCallPlanning={apiCallPlanning}
								lessonPlanId={lessonPlanId}
								setSaveData={setSaveData}
								selectedTab={selectedTab}
								ClassDetails={ClassDetails}
								setApiDatasEvaluation={setApiDatasEvaluation}
								questionsList={questionsList}
								deleteDynamicQuestionLessonPlanState={deleteDynamicQuestionLessonPlanState}
								deleteDynamicQuestionLessonPlan={deleteDynamicQuestionLessonPlan}
								setEvalSubmitFade={setEvalSubmitFade}
							/>
						</Tab>
					</Tabs>
				</div>)
			}
			<Modal centered className="delete-modal-warning br-8" show={goBackModal} >
                    <Modal.Header>
                        <Modal.Title>
                            <div style={{display : 'flex', flexDirection: 'column'}}>
                                <img src={warningIcon} alt="" style={{ width: "60px", height: "60px"}} />
                                <span style={{fontSize: '24px', color: '#ff1f1f', marginTop: '10px'}}>Wait!</span>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Do you want to go back your data will be lost. </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="default" className="cancel-btn-div cancel-btn-div-note cancelBtnLP" onClick={() => {   setGoBackModal(false);  }}
                        style={{marginRight : 18}}
                        >
                            Cancel
                        </Button>
                        <Button variant="default" className="goback-btn-div goback-btn-div-note goBackBtnLP" onClick={() => { 
							if(classBreadcrumb) {
								window.history.go(-2)
								setClassBreadcrumb(false)
								sessionStorage.removeItem('LPPlaiingQuestion')
								sessionStorage.removeItem('LPAudioList')
								sessionStorage.removeItem('LPTopic')
								sessionStorage.removeItem('LPTitle')
								sessionStorage.removeItem('LPUrllist')
								sessionStorage.removeItem('LPReminderTime')
								sessionStorage.removeItem('LPNotification')
								sessionStorage.removeItem('LPFormatDate')
								sessionStorage.removeItem('LPDocList')
								sessionStorage.removeItem('LPMinutes')
								sessionStorage.removeItem('LPPictureVideoList') 
							} else if(homeBreadcrumb) {
								BackToDash()	
								setHomeBreadcrumb(false)
								sessionStorage.removeItem('LPPlaiingQuestion')
								sessionStorage.removeItem('LPAudioList')
								sessionStorage.removeItem('LPTopic')
								sessionStorage.removeItem('LPTitle')
								sessionStorage.removeItem('LPUrllist')
								sessionStorage.removeItem('LPReminderTime')
								sessionStorage.removeItem('LPNotification')
								sessionStorage.removeItem('LPFormatDate')
								sessionStorage.removeItem('LPDocList')
								sessionStorage.removeItem('LPMinutes')
								sessionStorage.removeItem('LPPictureVideoList') 
							} else {
                            BackTOHome()
							sessionStorage.removeItem('LPPlaiingQuestion')
							sessionStorage.removeItem('LPAudioList')
							sessionStorage.removeItem('LPTopic')
							sessionStorage.removeItem('LPTitle')
							sessionStorage.removeItem('LPUrllist')
							sessionStorage.removeItem('LPReminderTime')
							sessionStorage.removeItem('LPNotification')
							sessionStorage.removeItem('LPFormatDate')
							sessionStorage.removeItem('LPDocList')
							sessionStorage.removeItem('LPMinutes')
							sessionStorage.removeItem('LPPictureVideoList') 
							}
        
        }}>
                            Go back
                        </Button>
                    </Modal.Footer>
                </Modal>
		</div>
	);
}
const mapStateToProps = state => ({
	state: state,
	addLessonPlanList: state.classLessonPlan.Add_Lesson_Plan,
	questionsList: state.classLessonPlan.Get_Question_List,
	saveExecutionLessonPlanState: state.classLessonPlan.Edit_Planning_Lesson_Plan,
	viewLessonPlanDetailsState: state.classLessonPlan.View_Lesson_Plan_Details,
	saveEvaluationLessonPlanState: state.classLessonPlan.Save_Evaluation_Lesson_Plan,
	deleteDynamicQuestionLessonPlanState: state.classLessonPlan.Delete_Dynamic_Question_Lesson_Plan
});
const mapDispatchToProps = dispatch => {
	return {
		addLessonPlan: (data) => dispatch(addLessonPlan(data)),
		getViewQuestionList: (data) => dispatch(getViewQuestionList(data)),
		viewLessonPlanDetails: (data) => dispatch(viewLessonPlanDetails(data)),
		saveExecutionLessonPlan: (data) => dispatch(saveExecutionLessonPlan(data)),
		saveEvaluationLessonPlan: (data) => dispatch(saveEvaluationLessonPlan(data)),
		deleteDynamicQuestionLessonPlan: (data) => dispatch(deleteDynamicQuestionLessonPlan(data)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(
	AddNewLessonPlan
);