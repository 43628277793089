import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import plusIcon from "Assets/NoteModule/plus.svg";
import flashIcon from "Assets/NoteModule/flash.svg";
import CardHeaderGroup from "Assets/NoteModule/CardHeaderGroup.svg";
import webLinkIcon from "Assets/NoteModule/webLink.png";
import { createNotification } from "Config/notificationtoast";
import EditIcon from "@mui/icons-material/Edit";
import Modal from "react-bootstrap/Modal";
import loaderImag from "Assets/ProfileModule/PepperyMedium.gif";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { PopoverHeader, PopoverBody, UncontrolledPopover } from "reactstrap";
import Form from "react-bootstrap/Form";
import { chroniclecloud_code, link_Url } from "Config/Constant";
import "./NoteCommentCard.scss";
import { useSelector } from "react-redux";
import { useSelection } from "../StudentNoteModule/StudentNoteAddComponent/useSelection";

const NoteCommentCard = (props) => {
  const { getSpeedNote, classTag, speedTagloader, getNoteSpiralTag } =
    props.allProps;
  const {
    CommentData,
    type,
    allData,
    setCommentData,
    width,
    class_id,
    pageType,
    studentInfo,
    StudentAllNoteDetails,
    note_tag_comments
  } = props;
  console.log(props)
  const [AllSpeedNoteData, setAllSpeedNoteData] = useState([]);
  const [SpeedNoteData, setSpeedNoteData] = useState([]);
  const [tagId, setTagId] = useState(0);
  const [ctagId, setcTagId] = useState(0);

  const [searchText, setSearchText] = "";

  const [readerRef, setReaderRef] = useState(null);
  const [tooltipRef, setTooltipRef] = useState(null);
  const [closeButtonRef, setCloseButtonRef] = useState(null);
  const [showTranscribeAudioModal, setShowTranscribeAudioModal] =
    useState(false);
  const [editAudioTranscribeAndSummarize, setEditAudioTranscribeAndSummarize] =
    useState(false);
  const [audioTagCommentText, setAudioCommentText] = useState(null);
  const [audioInitialCommentText, setAudioInitialCommentText] = useState(null);
  const [audioTagTitle, setAudioTagTitle] = useState(null);
  const [CommentFinalData, setCommentFinalData] = useState([]);
  const [groupNoteCommentTags, setGroupNoteCommentTags] = useState([]);
  // let CommentFinalData = [];
  const { selection } = useSelection(readerRef, tooltipRef, closeButtonRef);

  const corestandards = useSelector(
    (state) => state?.classStandard?.get_note_spiral_tag?.data
  );

  // useEffect(() => {
  //     if(tagId > 0) {
  //     let data = {
  //         "customize_tag_id": tagId,
  //         "class_id": class_id,
  //         "filter_text": ""
  //     }
  //     getSpeedNote(data)
  // }
  // },[CommentData])

  // const highlightHandler = (text) => {
  //   const { CommentData } = props;
  //   let customtoolTipDisplay = document.getElementById("tooltip");
  //   customtoolTipDisplay.style.display = "none";
  //   document.getSelection().removeAllRanges(); // Remove the text selection

  //   let dataArray = [...CommentData];

  //   if (pageType === "Edit") {
  //     // Find the index of the object with cc_tag_title equal to text
  //     const positiveCommentsIndex = dataArray.findIndex(
  //       (obj) => obj.cc_tag_title === text
  //     );

  //     // Check if the object exists and tag_comment_text is empty
  //     if (
  //       positiveCommentsIndex !== -1 &&
  //       dataArray[positiveCommentsIndex].tag_comment_text === ""
  //     ) {
  //       // Update tag_comment_text
  //       dataArray[positiveCommentsIndex].tag_comment_text = selection;
  //     } else {
  //       // Create a new object with empty tag_comment_text right after the updated object
  //       const newObject = {
  //         cc_tag_abbre: dataArray[positiveCommentsIndex]?.cc_tag_abbre,
  //         cc_tag_title: dataArray[positiveCommentsIndex]?.cc_tag_title,
  //         tag_comment_add_on: new Date(),
  //         tag_comment_id: "",
  //         tag_comment_note_id:
  //           dataArray[positiveCommentsIndex]?.tag_comment_note_id,
  //         tag_comment_student_id:
  //           dataArray[positiveCommentsIndex]?.tag_comment_student_id,
  //         tag_comment_text: selection,
  //         tag_id: "",
  //         // cc_tag_abbre: dataArray[positiveCommentsIndex].cc_tag_abbre,
  //         // cc_tag_title: text,
  //         // tag_comment_add_on: new Date(),
  //         // tag_comment_id: "",
  //         // tag_comment_note_id: dataArray[positiveCommentsIndex].tag_comment_note_id,
  //         // tag_comment_student_id: dataArray[positiveCommentsIndex].tag_comment_student_id,
  //         // tag_comment_text: selection,
  //         // tag_id: "",
  //       };
  //       // Insert the new object right after the updated object
  //       dataArray.splice(positiveCommentsIndex + 1, 0, newObject);
  //     }
  //     sessionStorage.setItem(
  //       "finalNoteCommentCardsAfterRemove",
  //       JSON.stringify(dataArray)
  //     );
  //     setCommentData(dataArray);
  //   } else {
  //     // Find the index of the object with cc_tag_title equal to text
  //     const positiveCommentsIndex = dataArray.findIndex(
  //       (obj) => obj.cc_tag_title === text
  //     );

  //     // Check if the object exists and tag_comment_text is empty
  //     if (
  //       positiveCommentsIndex !== -1 &&
  //       dataArray[positiveCommentsIndex].tag_comment_text === ""
  //     ) {
  //       // Update tag_comment_text
  //       dataArray[positiveCommentsIndex].tag_comment_text = selection;
  //     } else {
  //       // Create a new object with empty tag_comment_text right after the updated object
  //       const newObject = {
  //         cc_tag_abbre: dataArray[positiveCommentsIndex]?.cc_tag_abbre,
  //         cc_tag_add_on: new Date(),
  //         cc_tag_checked: "no",
  //         cc_tag_class_id: "",
  //         cc_tag_id: "",
  //         cc_tag_modifyed_on: new Date(),
  //         tag_comment_text: selection,
  //         cc_tag_title: dataArray[positiveCommentsIndex]?.cc_tag_title,
  //         // cc_tag_abbre: dataArray[positiveCommentsIndex].cc_tag_abbre,
  //         // cc_tag_add_on: new Date(),
  //         // cc_tag_checked: "no",
  //         // cc_tag_class_id: props?.class_id,
  //         // cc_tag_id: "",
  //         // cc_tag_modifyed_on: new Date(),
  //         // tag_comment_text: selection,
  //         // cc_tag_title: text,
  //       };
  //       // Insert the new object right after the updated object
  //       dataArray.splice(positiveCommentsIndex + 1, 0, newObject);
  //     }
  //     sessionStorage.setItem(
  //       "finalNoteCommentCardsAfterRemove",
  //       JSON.stringify(dataArray)
  //     );
  //     setCommentData(dataArray);
  //   }
  //   // Find the index of the object with cc_tag_title equal to text
  //   // const positiveCommentsIndex = dataArray.findIndex(
  //   //     (obj) => obj.cc_tag_title === text
  //   // );

  //   // // Check if the object exists and tag_comment_text is empty
  //   // if (positiveCommentsIndex !== -1 && dataArray[positiveCommentsIndex].tag_comment_text === '') {
  //   //     // Update tag_comment_text
  //   //     dataArray[positiveCommentsIndex].tag_comment_text = selection
  //   // } else {
  //   //     // Create a new object with empty tag_comment_text right after the updated object
  //   // const newObject = {
  //   //     cc_tag_abbre: dataArray[positiveCommentsIndex].cc_tag_abbre,
  //   //     cc_tag_add_on: new Date(),
  //   //     cc_tag_checked: "no",
  //   //     cc_tag_class_id: props?.class_id,
  //   //     cc_tag_id: "",
  //   //     cc_tag_modifyed_on: new Date(),
  //   //     tag_comment_text: selection,
  //   //     cc_tag_title: text,
  //   // };
  //   //   // Insert the new object right after the updated object
  //   //   dataArray.splice(positiveCommentsIndex + 1, 0, newObject);
  //   // }
  //   // setCommentData(dataArray);
  // };
  const highlightHandler = (text) => {
    const { CommentData } = props;
    let customtoolTipDisplay = document.getElementById("tooltip");
    customtoolTipDisplay.style.display = "none";
    document.getSelection().removeAllRanges(); // Remove the text selection

    let dataArray = [...CommentData];

    // Common logic for both "Edit" and non-"Edit" modes
    const findLastIndexOfTitle = (title) => {
        return dataArray.reduce((lastIndex, obj, currentIndex) => {
            return obj.cc_tag_title === title ? currentIndex : lastIndex;
        }, -1);
    };

    const lastIndexOfTitle = findLastIndexOfTitle(text);

    if (pageType === "Edit") {
        if (
            lastIndexOfTitle !== -1 &&
            dataArray[lastIndexOfTitle].tag_comment_text === ""
        ) {
            dataArray[lastIndexOfTitle].tag_comment_text = selection;
        } else {
            const newObject = {
                cc_tag_abbre: dataArray[lastIndexOfTitle].cc_tag_abbre,
                cc_tag_title: text,
                tag_comment_add_on: new Date(),
                tag_comment_id: "",
                tag_comment_note_id: dataArray[lastIndexOfTitle].tag_comment_note_id,
                tag_comment_student_id: dataArray[lastIndexOfTitle].tag_comment_student_id,
                tag_comment_text: selection,
                tag_id: "",
            };
            dataArray.splice(lastIndexOfTitle + 1, 0, newObject);
        }
    } else {
        if (
            lastIndexOfTitle !== -1 &&
            dataArray[lastIndexOfTitle].tag_comment_text === ""
        ) {
            dataArray[lastIndexOfTitle].tag_comment_text = selection;
        } else {
            const newObject = {
                cc_tag_abbre: dataArray[lastIndexOfTitle].cc_tag_abbre,
                cc_tag_add_on: new Date(),
                cc_tag_checked: "no",
                cc_tag_class_id: props?.class_id,
                cc_tag_id: "",
                cc_tag_modifyed_on: new Date(),
                tag_comment_text: selection,
                cc_tag_title: text,
            };
            dataArray.splice(lastIndexOfTitle + 1, 0, newObject);
        }
    }

    sessionStorage.setItem(
        "finalNoteCommentCardsAfterRemove",
        JSON.stringify(dataArray)
    );
    setCommentData(dataArray);

};
  useEffect(() => {
    if (classTag.action === "GET_SPEED_NOTE_SUCCESS") {
      if (classTag.Get_Speed_Note) {
        let SpeedNote = [];
        let data = classTag.Get_Speed_Note;

        Object.keys(data).forEach(function (key) {
          for (let item of data[key]) {
            SpeedNote.push(item);
          }
        });
        setSpeedNoteData(SpeedNote);
        setAllSpeedNoteData(SpeedNote);
      }
    }
  }, [classTag.Get_Speed_Note, classTag.action]);
  // ---------componentWillReceiveProps-------

  // ---------Add New Tag-------
  // const AddNewTag = (index) => {
  //   let data = [];
  //   let CommentDataEdit = CommentData;
  //   if (CommentDataEdit[index].tag_comment_text === "") {
  //     createNotification(
  //       "error",
  //       " Please Fill " + CommentDataEdit[index].cc_tag_title
  //     );
  //     return;
  //   }

  //   if (pageType === "Edit") {
  //     data = {
  //       cc_tag_abbre: CommentDataEdit[index].cc_tag_abbre,
  //       cc_tag_title: CommentDataEdit[index].cc_tag_title,
  //       tag_comment_add_on: new Date(),
  //       tag_comment_id: "",
  //       tag_comment_note_id: CommentDataEdit[index].tag_comment_note_id,
  //       tag_comment_student_id: CommentDataEdit[index].tag_comment_student_id,
  //       tag_comment_text: "",
  //       tag_id: "",
  //     };
  //   } else {
  //     data = {
  //       cc_tag_abbre: CommentDataEdit[index].cc_tag_abbre,
  //       cc_tag_add_on: new Date(),
  //       cc_tag_checked: "no",
  //       cc_tag_class_id: "",
  //       cc_tag_id: "",
  //       cc_tag_modifyed_on: new Date(),
  //       tag_comment_text: "",
  //       cc_tag_title: CommentDataEdit[index].cc_tag_title,
  //     };
  //   }
  //   let mainIndex = index + 1;
  //   CommentDataEdit.splice(mainIndex, 0, data);
  //   sessionStorage.setItem(
  //     "finalNoteCommentCardsAfterRemove",
  //     JSON.stringify(CommentDataEdit)
  //   );
  //   setCommentData(CommentDataEdit);
  // };


  const AddNewTag = (item, index) => {
    const { CommentData, pageType } = props;
    let CommentDataEdit = [...CommentData]; // Create a copy of CommentData to avoid mutating props directly

    if (CommentDataEdit[index]?.tag_comment_text === "") {
        createNotification(
            "error",
            " Please Fill " + CommentDataEdit[index].cc_tag_title
        );
        return;
    }

    const currentTitle = CommentDataEdit[index]?.cc_tag_title;

    // Check if there's already an existing element with the same title and empty tag_comment_text
    const existingEmptyTag = CommentDataEdit.some(
        (tag, idx) => 
            tag.cc_tag_title === currentTitle && 
            tag.tag_comment_text === "" && 
            idx !== index
    );

    if (existingEmptyTag) {
        createNotification(
            "error",
            `An empty tag with the title "${currentTitle}" already exists. Please fill it before adding a new one.`
        );
        return;
    }

    let data = {};
    if (pageType === "Edit") {
        data = {
            cc_tag_abbre: CommentDataEdit[index].cc_tag_abbre,
            cc_tag_title: CommentDataEdit[index].cc_tag_title,
            tag_comment_add_on: new Date(),
            tag_comment_id: "",
            tag_comment_note_id: CommentDataEdit[index].tag_comment_note_id,
            tag_comment_student_id: CommentDataEdit[index].tag_comment_student_id,
            tag_comment_text: "",
            tag_id: "",
        };
    } else {
        data = {
            cc_tag_abbre: CommentDataEdit[index]?.cc_tag_abbre,
            cc_tag_add_on: new Date(),
            cc_tag_checked: "no",
            cc_tag_class_id: "",
            cc_tag_id: "",
            cc_tag_modifyed_on: new Date(),
            tag_comment_text: "",
            cc_tag_title: CommentDataEdit[index]?.cc_tag_title,
        };
    }

    // Find the last occurrence of the same title
    let lastSameTitleIndex = -1;
    for (let i = 0; i < CommentDataEdit.length; i++) {
        if (CommentDataEdit[i].cc_tag_title === currentTitle) {
            lastSameTitleIndex = i;
        }
    }

    // Insert the new tag right after the last occurrence of the same title
    if (lastSameTitleIndex !== -1) {
        CommentDataEdit.splice(lastSameTitleIndex + 1, 0, data);
    }

    setCommentData(CommentDataEdit);

};
  // ---------Add New Tag-------
  const identifyDuplicateTags = (CommentData) => {
    const tagOccurrences = {};
    
    CommentData.forEach((item, index) => {
        const title = item.cc_tag_title;
        
        // Track occurrences of each title
        if (!tagOccurrences[title]) {
            tagOccurrences[title] = [];
        }
        tagOccurrences[title].push(index);
    });
    
    return tagOccurrences;
  };
  
  
  // Get the occurrences of duplicate tags
  const tagOccurrences = identifyDuplicateTags(CommentData);
  // ---------Input Handle change-------

  const [TagCommentText, setTagCommentText] = useState()
  const OnChangeTextArea = (event, tagId, index) => {
    const { CommentData } = props;
    console.log('CommentData %%% &&& :>> ', CommentData);
    let CommentDataEdit = [...CommentData]; // Create a shallow copy of CommentData
  
    if (props.pageType == 'Edit') {
      if (tagId?.length > 0 || tagId > 0) {
        CommentDataEdit = CommentDataEdit.map(item => {
          if (item.tag_comment_id === tagId) {
            return {
              ...item,
              tag_comment_text: event.target.value, // Update the text
            };
          }
          return item;
        });
      } else {
        CommentDataEdit[index] = {
          ...CommentDataEdit[index],
          tag_comment_text: event.target.value, // Update the text using index
        };
      }
    } else {
      if (tagId?.length > 0 || tagId > 0) {
        CommentDataEdit = CommentDataEdit.map(item => {
          if (item.cc_tag_id === tagId) {
            return {
              ...item,
              tag_comment_text: event.target.value, // Update the text
            };
          }
          return item;
        });
      } else {
        CommentDataEdit[index] = {
          ...CommentDataEdit[index],
          tag_comment_text: event.target.value, // Update the text using index
        };
      }
    }
  
    // Update the state and session storage with the new CommentData array
    setCommentData(CommentDataEdit);
    sessionStorage.setItem('noteCommentData', JSON.stringify(CommentDataEdit));
  
  };
  // const OnChangeTextArea = (event, tagId, index) => {
  //   console.log(event.target.value, tagId, index);
  //   const { CommentData } = props;
  //   let CommentDataEdit = [...CommentData];
  //   if(props.pageType == 'Edit'){
  //     if(tagId?.length > 0 || tagId > 0) {
  //     CommentDataEdit = CommentDataEdit.map(item => {
  //       if (item.tag_comment_id === tagId) { // Replace idToModify with the actual ID you want to modify
  //         // Modify the details of the object here
  //         return {
  //           ...item,
  //           tag_comment_text:event.target.value,
  //           // Add other properties you want to modify
  //         };
  //       }
  //       return item; // Return the item unchanged if it doesn't match the ID
  //     });
  //     props?.setCommentData(CommentDataEdit);
  //     sessionStorage.setItem('noteCommentData', JSON.stringify(CommentDataEdit))
  //   } else {
  //     let CommentDataEdit = CommentData;
  //     CommentDataEdit[index].tag_comment_text = event.target.value;
  //     props?.setCommentData(CommentDataEdit);
  //     // sessionStorage.setItem('noteCommentData', JSON.stringify(CommentDataEdit))
  //   }
  //   } else {
  //     if(tagId?.length > 0 || tagId > 0) {
  //     let CommentDataEdit = CommentData.map(item => {
  //       if (item.cc_tag_id === tagId) { // Replace idToModify with the actual ID you want to modify
  //         // Modify the details of the object here
  //         return {
  //           ...item,
  //           tag_comment_text:event.target.value,
  //           // Add other properties you want to modify
  //         };
  //       }
  //       return item; // Return the item unchanged if it doesn't match the ID
  //     });
  //     props?.setCommentData(CommentDataEdit);
  //     sessionStorage.setItem('noteCommentData', JSON.stringify(CommentDataEdit))
  //   } else {
  //     let CommentDataEdit = CommentData;
  //     CommentDataEdit[index].tag_comment_text = event.target.value;
  //     props?.setCommentData(CommentDataEdit);
  //     // sessionStorage.setItem('noteCommentData', JSON.stringify(CommentDataEdit))
  //   }
  //   }

  //   // let CommentDataEdit = CommentData.map(item => {
  //   //   if (item.cc_tag_id === index || item.tag_comment_id === index) { // Replace idToModify with the actual ID you want to modify
  //   //     // Modify the details of the object here
  //   //     return {
  //   //       ...item,
  //   //       tag_comment_text:event.target.value,
  //   //       // Add other properties you want to modify
  //   //     };
  //   //   }
  //   //   return item; // Return the item unchanged if it doesn't match the ID
  //   // });
  //   // sessionStorage.setItem(
  //   //   "finalNoteCommentCardsAfterRemove",
  //   //   JSON.stringify(CommentDataEdit)
  //   // );
  //   // setCommentData(CommentDataEdit);
  // };
  // ---------Input Handle change-------

  // ---------addSpeedNote-------
  const addSpeedNote = (value, index) => {
    const { CommentData } = props;
    let CommentDataEdit = CommentData;
    CommentDataEdit[index].tag_comment_text = value;
    CommentDataEdit[index].editOpen = !CommentDataEdit[index].editOpen;
    sessionStorage.setItem(
      "finalNoteCommentCardsAfterRemove",
      JSON.stringify(CommentDataEdit)
    );
    setCommentData(CommentDataEdit);
  };
  // ---------addSpeedNote-------

  // ---------addCoreStandard-------
  const addCoreStandard = (value, index) => {
    const { CommentData } = props;
    let CommentDataEdit = CommentData;
    CommentDataEdit[index].tag_comment_text = value;
    CommentDataEdit[index].editOpen2 = !CommentDataEdit[index].editOpen2;
    sessionStorage.setItem(
      "finalNoteCommentCardsAfterRemove",
      JSON.stringify(CommentDataEdit)
    );
    setCommentData(CommentDataEdit);
  };
  // ---------addCoreStandard-------

  // ---------Submit assignment--------
  const openNewTab = (item) => {
    if (StudentAllNoteDetails.student_details.storage_flag === 0) {
      createNotification(
        "error",
        StudentAllNoteDetails.student_details.storage_message
      );
      return;
    }
    let data = JSON.parse(sessionStorage.getItem("UserData"));
    const BODY = JSON.stringify({
      chroniclecloud_code: chroniclecloud_code,
      user_id: data.cc_user_id,
      note_id: item.cc_note_id,
      cmd: "get_student_note",
      submit: "submit",
      device_udid: sessionStorage.getItem("UDID"),
      class_id: item.cc_note_class_id,
      student_id: studentInfo.cc_student_id,
    });
    let url = link_Url + btoa(BODY);
    window.open(url);
    // window.open("https://dev-teachers.chroniclecloud.com/?token=eyJjaHJvbmljbGVjbG91ZF9jb2RlIjoiWTJoeWIyNXBZMnhsZFhObGNueFFZWE56ZDI5eVpDTXhNRFU9IiwidXNlcl9pZCI6IjE1NTQiLCJub3RlX2lkIjoiMTUwMDMiLCJjbWQiOiJnZXRfc3R1ZGVudF9ub3RlIiwic3VibWl0Ijoic3VibWl0IiwiZGV2aWNlX3VkaWQiOiJBNUQxNkY0Qy05NDM3LTQ2RTktQUE2My00QUVGNzNBMEJDMzEiLCJjbGFzc19pZCI6IjI5OTciLCJzdHVkZW50X2lkIjoiMTg5NzEifQ%3D%3D")
  };
  // -------Submit assignment-------
  const deleteTagHandler = (item, index) => {


    const updatedCommentData = CommentData.filter((_, i) => i !== index);

    // Log the updated data to see the change
  //   dataArray.splice(indexOfElement, 1);
    sessionStorage.setItem(
      "finalNoteCommentCardsAfterRemove",
      JSON.stringify(updatedCommentData)
    );
    setCommentData(updatedCommentData);
  // };
}
  // -------Make Speed Tag  -------
  const selectSpeedTag = (item, index) => {
    setTagId(item.cc_tag_id);
    let data = [];
    setSpeedNoteData([]);
    setAllSpeedNoteData([]);
    if (pageType === "Edit") {
      if (item.tag_id === "") {
        createNotification("error", "Please save the new Tag first.");
        return;
      } else {
        data = {
          customize_tag_id: item.tag_id,
          class_id: class_id,
          filter_text: "",
        };
      }
    } else {
      if (item.cc_tag_id === "") {
        createNotification("error", "Please save the new Tag first.");
        return;
      } else {
        data = {
          customize_tag_id: item.cc_tag_id,
          class_id: class_id,
          filter_text: "",
        };
      }
    }
    let CommentDataEdit = CommentData;
    CommentDataEdit[index].editOpen = !CommentDataEdit[index].editOpen;
    sessionStorage.setItem(
      "finalNoteCommentCardsAfterRemove",
      JSON.stringify(CommentDataEdit)
    );
    setCommentData(CommentDataEdit);
    if (CommentDataEdit[index].editOpen) {
      getSpeedNote(data);
    }
  };
  // ---------Make Speed Tag  -------

  // ---------Select Core Standard  -------
  const selectCoreStandard = (item, index) => {
    setcTagId(item.cc_speed_note_id);

    let data = [];
    setSpeedNoteData([]);
    setAllSpeedNoteData([]);
    if (pageType === "Edit") {
      if (item.tag_id === "") {
        createNotification("error", "Please save the new Tag first.");
        return;
      } else {
        data = {
          customize_tag_id: item.tag_id,
          class_id: class_id,
          filter_text: "",
        };
      }
    } else {
      if (item.cc_tag_id === "") {
        createNotification("error", "Please save the new Tag first.");
        return;
      } else {
        data = {
          customize_tag_id: item.cc_tag_id,
          class_id: class_id,
          filter_text: "",
        };
      }
    }
    let CommentDataEdit = CommentData;
    CommentDataEdit[index].editOpen2 = !CommentDataEdit[index].editOpen2;
    sessionStorage.setItem(
      "finalNoteCommentCardsAfterRemove",
      JSON.stringify(CommentDataEdit)
    );
    setCommentData(CommentDataEdit);
    if (CommentDataEdit[index].editOpen2) {
      getNoteSpiralTag(data);
    }
  };
  // ---------Select Core Standard  -------

  // ---------Local Auto search  -------
  const handleSearchText = (event) => {
    setSearchText(event.target.value);
    let tempArray = [];
    for (let item of AllSpeedNoteData) {
      let text = item.cc_speed_note_text.toLowerCase();
      let search = event.target.value.toLowerCase();
      if (text.includes(search) === true) {
        tempArray.push(item);
      }
    }
    setSpeedNoteData(tempArray);
  };
  // ---------Local Auto search  -------

  useEffect(() => {
    if (CommentData) {
        for (let item of CommentData) {
          if (item.tag_comment_text?.length > 0) {
            // CommentFinalData.push(item);
            setCommentFinalData([...CommentFinalData, item]);
          }
        }
    }
  }, []);

  const handleGenerateAudioAiModalClose = () => {
    setShowTranscribeAudioModal(false);
    setEditAudioTranscribeAndSummarize(false);
  };

  const saveTransciptionSummaryModal = () => {
    setEditAudioTranscribeAndSummarize(false);
    setShowTranscribeAudioModal(false);
    let dataArray = [...CommentData];
    // Find the index of the object with cc_tag_title equal to text
    const positiveCommentsIndex = dataArray.findIndex(
      (obj) => obj.cc_tag_title === audioTagTitle
    );
    if (positiveCommentsIndex !== -1) {
      // Update tag_comment_text
      dataArray[positiveCommentsIndex].tag_comment_text = audioTagCommentText;
    }
    setCommentData(dataArray);
  };

  const deleteAudioTagHandler = (text) => {
    let dataArray = [...CommentData];
    const index = dataArray.indexOf(
      dataArray.filter((item) => item.cc_tag_title === text)[0]
    );
    dataArray.splice(index, 1);
    sessionStorage.setItem(
      "finalNoteCommentCardsAfterRemove",
      JSON.stringify(dataArray)
    );
    setCommentData(dataArray);
  };
  console.log(type)
  return (
    <>
      {type === "view" ? (
        <Row className="timeline-section-div">
          {/* ------Show comment card Section----- */}
          {CommentFinalData &&
            CommentFinalData.length > 0 &&
            CommentFinalData.map((item, index) => (
              <Col xs="12" key={index} md={width} className="adv-note-div">
                <Card className="adv-note">
                  <Card.Header>
                    <span className="header-text">
                      {type === "Edit" ? (
                        <img src={flashIcon} alt="" width="18" height="18" />
                      ) : (
                        ""
                      )}{" "}
                      {item.cc_tag_title}
                    </span>
                  </Card.Header>
                  <Card.Body>
                    <Card.Text>{item.tag_comment_text}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}

          {/* ------Show Assignment card Section----- */}
          <Col xs="12" md={width} className="adv-note-ass-div">
   
            <Card className="adv-note-ass">
              <Card.Header>
                <span className="header-text">
                  {type === "Edit" ? (
                    <img src={flashIcon} alt="" width="18" height="18" />
                  ) : (
                    ""
                  )}{" "}
                  Assignment
                </span>

                {allData.cc_to_show_submission_option === 0 ? (
                  ""
                ) : (
                  <Button
                    onClick={() => {
                      openNewTab(allData);
                    }}
                  >
                    <img src={webLinkIcon} alt="" width="16" height="16" />
                    <span className="submit-text">Submitted Work</span>
                  </Button>
                )}
              </Card.Header>
              <Card.Body>
                <Card.Text>{allData.cc_work_assignment}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          {/* ------Show Assignment card Section----- */}

          {/* ------Show comment card Section----- */}
        </Row>
      ) : (
        <>
       {CommentData?.some(item => (
      (props.pageType !== "Edit" && props.showDivs && 
       (item?.cc_tag_title === "Audio Transcription" || item?.cc_tag_title === "Audio Summarization")) ||
      (props.pageType === "Edit" && 
       (
         (item?.cc_tag_title === "Audio Transcription" && CommentData[1]?.tag_comment_text !== "") ||
         (item?.cc_tag_title === "Audio Summarization" && item?.tag_comment_text !== "")
       ))
    )) && (
      <p style={{background: "#FFEBA0", margin: "5px"}} className='hintt'>
        Hint: Select data to tag as type of comment i.e. positive comment
      </p>
    )}
        <Row>

          {/* ------Edit comment card Section----- */}
          {
            console.log('CommentData NCC :>> ', CommentData)
          }
          {CommentData &&
            CommentData.length > 0 &&
            CommentData.map(
              (item, index) => (
                console.log('CommentData111 :>> ', CommentData , props.pageType),
                console.log(item),
                (
                  <Col key={item?.cc_tag_id || item?.tag_comment_id || index} xs="12" md={width} className="adv-note-div"
                  style={{
                    display: 
                      (props.pageType !== "Edit" && !props.showDivs && 
                       (item?.cc_tag_title === "Audio Transcription" || item?.cc_tag_title === "Audio Summarization")) ||
                      (props.pageType === "Edit" && 
                       (
                          (item?.cc_tag_title === "Audio Transcription" && CommentData[1]?.tag_comment_text === "") ||
                          (item?.cc_tag_title === "Audio Summarization" && item?.tag_comment_text === "")
                       ))
                        ? 'none'
                        : 'block'
                  }}
                  >
                    <Card className="adv-note">
                      <Card.Header>
                        {/* ------Title of card----- */}
                        <span className="header-text">
                          <img
                             style={(item.cc_tag_title == "Audio Transcription" || item.cc_tag_title == "Audio Summarization")  ? {visibility : "hidden"}  : { cursor: "pointer", marginRight: "5px"}}
                            id={`mypopover${index}`}
                            src={flashIcon}
                            alt=""
                            width="18"
                            height="18"
                            onClick={() => {
                              selectSpeedTag(item, index);
                            }}
                          />
                          {item.cc_tag_title}
                        </span>
                        {/* ------Title of card----- */}

                        {/* ------popover for add Speed Tag----- */}
                        <UncontrolledPopover
                          trigger="legacy"
                          placement="right"
                          isOpen={item.editOpen}
                          target={`mypopover${index}`}
                          toggle={() =>
                            selectSpeedTag(item, index, item.cc_tag_id)
                          }
                          style={{ borderRadius: 20 }}
                          className="popoverTag"
                        >
                          <PopoverHeader>Speed Notes</PopoverHeader>
                          <PopoverBody>
                            <div className="searchTagdiv">
                              <div className="input-group-prepend border-0">
                                <button
                                  id="button-addon4"
                                  type="button"
                                  className="btn btn-link text-info searchIconDiv"
                                >
                                  <i className="fa fa-search searchIcon"></i>
                                </button>
                              </div>
                              <input
                                margin="normal"
                                type="text"
                                value={searchText}
                                onChange={handleSearchText}
                                name="searchText"
                                className="searchTagInput"
                                placeholder="Search Speed Note Here"
                              ></input>
                            </div>

                            {SpeedNoteData && SpeedNoteData.length > 0 ? (
                              <div className="tagDiv">
                                {SpeedNoteData.map((item, i) => (
                                  <div
                                    key={i}
                                    className="selectTagDiv"
                                    onClick={() => {
                                      addSpeedNote(
                                        item.cc_speed_note_text,
                                        index
                                      );
                                    }}
                                  >
                                    <p className="selectTagText">
                                      {item.cc_speed_note_text}
                                    </p>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <div>
                                {speedTagloader ? (
                                  <div>
                                    <img
                                      src={loaderImag}
                                      alt=""
                                      className="loaderIconCss"
                                    />
                                  </div>
                                ) : (
                                  <div className="selectTagDiv">
                                    <p className="selectTagText">
                                      No Data Found
                                    </p>
                                  </div>
                                )}
                              </div>
                            )}
                          </PopoverBody>
                        </UncontrolledPopover>
                        {/* ------popover for add Speed Tag----- */}

                        {/* -----Add new comment card ---- */}
                        <Button
                          onClick={() => {
                            // AddNewTag(index);
                            // deleteAudioTagHandler(item?.cc_tag_title);
                            deleteTagHandler(item, index)
                          }}
                          style={{
                            marginLeft: "8px",
                            display:
                              item?.cc_tag_title == "Audio Transcription" ||
                              item?.cc_tag_title == "Audio Summarization"
                                ? "block"
                                : "none",
                          }}
                          className="eyeBtnSvg"
                        >
                          <DeleteIcon />
                        </Button>
                        <Button
                          onClick={() => {
                            // AddNewTag(index);
                            setAudioCommentText(item?.tag_comment_text);
                            setShowTranscribeAudioModal(true);
                            setAudioInitialCommentText(item?.tag_comment_text);
                            setAudioTagTitle(item?.cc_tag_title);
                          }}
                          style={{
                            marginLeft: "8px",
                            display:
                              item?.cc_tag_title == "Audio Transcription" ||
                              item?.cc_tag_title == "Audio Summarization"
                                ? "block"
                                : "none",
                            opacity:
                              item?.tag_comment_text?.trim().length == 0
                                ? "0.5"
                                : "1",
                          }}
                          className="eyeBtnSvg"
                          disabled={item?.tag_comment_text?.trim().length == 0}
                        >
                          <VisibilityOutlinedIcon />
                        </Button>
                        <Button
                          onClick={() => {
                            AddNewTag(item, index);
                          }}
                          style={{
                            marginLeft: "3px",
                            display:
                              item?.cc_tag_title == "Audio Transcription" ||
                              item?.cc_tag_title == "Audio Summarization"
                                ? "none"
                                : "block",
                          }}
                        >
                          <img src={plusIcon} alt="" width="18" height="18" />
                        </Button>
                        <Button
                          onClick={() => {
                            // AddNewTag(index);
                            // deleteAudioTagHandler(item?.cc_tag_title , index);
                            deleteTagHandler(item, index)
                          }}
                          style={{
                            marginLeft: "4px",
                            display:
                                // Show the delete icon only for duplicates if pageType is "delete"
                                props?.pageType === "Edit" ?
                                tagOccurrences[item.cc_tag_title]?.length > 1 &&
                                tagOccurrences[item.cc_tag_title]?.indexOf(index) !== 0
                                    ? "block"
                                    : "none" :
                                     //   display:
                              item?.cc_tag_id == "" || item?.tag_comment_id == ""

                                ? "block"
                                : "none",
                        }}
                          // style={{
                          //   marginLeft: "8px",
                          //   display:
                          //     item?.cc_tag_id == "" || item?.tag_comment_id == ""

                          //       ? "block"
                          //       : "none",
                          // }
                        
                          className="eyeBtnSvg"
                        >
                          <div style={{    fontSize: "22px"}} className="delicon">

                          <DeleteIcon  />
                          </div>
                        </Button>
                        <Button>
                          <img
                            src={CardHeaderGroup}
                            alt=""
                            style={{
                              width: "18",
                              height: "18",
                              display:
                                item?.cc_tag_title == "Audio Transcription" ||
                                item?.cc_tag_title == "Audio Summarization"
                                  ? "none"
                                  : "inline",
                                  position: "relative",
                                  top: "1px",
                                  left: "2px"
                            }
                            
                          }
                            onClick={() => {
                              selectCoreStandard(
                                item,
                                index,
                                item.cc_speed_note_id
                              );
                            }}
                            id={`mypopover2${index}`}
                          />
                        </Button>
                        {/* -----Add new comment card ---- */}
                        {/* ------popover for core standard----- */}
                        <UncontrolledPopover
                          trigger="legacy"
                          placement="right"
                          isOpen={item.editOpen2}
                          target={`mypopover2${index}`}
                          toggle={() => selectCoreStandard(item, index)}
                          style={{ borderRadius: 20 }}
                          className="popoverTag"
                        >
                          <PopoverHeader>Core Standard</PopoverHeader>
                          <PopoverBody>
                            {/* <div className="searchTagdiv">
                                            <div className="input-group-prepend border-0">
                                                <button id="button-addon4" type="button" className="btn btn-link text-info searchIconDiv"><i className="fa fa-search searchIcon"></i></button>
                                            </div>
                                            <input
                                                margin="normal"
                                                type='text'
                                                value={searchText}
                                                onChange={handleSearchText}
                                                name="searchText"
                                                className='searchTagInput'
                                                placeholder="Search Speed Note Here"
                                            ></input>
                                        </div> */}
                            {corestandards && corestandards.length > 0 ? (
                              <div className="tagDiv">
                                {corestandards.map((item, i) => (
                                  <div
                                    key={i}
                                    className="selectTagDiv"
                                    onClick={() => {
                                      addCoreStandard(
                                        item?.arr?.[0]?.description,
                                        index
                                      );
                                    }}
                                  >
                                    <p className="selectTagText">
                                      {item?.arr?.[0]?.description}
                                    </p>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <div>
                                {speedTagloader ? (
                                  <div>
                                    <img
                                      src={loaderImag}
                                      alt=""
                                      className="loaderIconCss"
                                    />
                                  </div>
                                ) : (
                                  <div className="selectTagDiv">
                                    <p className="selectTagText">
                                      No Data Found
                                    </p>
                                  </div>
                                )}
                              </div>
                            )}
                          </PopoverBody>
                        </UncontrolledPopover>
                        {/* ------popover for core standard----- */}
                      </Card.Header>
                      <Card.Body className="padding0">
                
                        {/* -----comment card for edit---- */}
                        {item?.cc_tag_title == "Audio Summarization" ? (
                          <>
                     {     console.log('props?.audioSummarizationtext || item.tag_comment_text :>> ', props?.audioSummarizationtext , item.tag_comment_text)}
                            <Card.Text>
                              <Form.Control
                                name="tag_comment_text"
                                className="editTextArea demo2"
                                as="textarea"
                                rows="7"
                                value={props?.pageType == 'Edit' ? (props?.audioSummarizationtext || item.tag_comment_text) :  (props?.audioSummarizationtext || item.tag_comment_text)}
                                onChange={(e) => {
                                  OnChangeTextArea(e, (item?.tag_comment_id || item?.cc_tag_id), index);
                                }}
                                // onMouseUp={(e) => {
                                //     logH5Content(e)
                                // }}
                                ref={setReaderRef}
                                id="newFormId"
                                // style={{fontSize: 17}}
                                disabled = {item.cc_tag_title == "Audio Transcription"}
                                readOnly={item.cc_tag_title === "Audio Summarization"} 
                              />
                              <div
                                ref={setTooltipRef}
                                id="tooltip"
                                style={{
                                  display: "none",
                                  padding: "10px",
                                  background: "#fff",
                                  boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                                  position: "absolute",
                                  zIndex: 999999,
                                  //   zIndex: 999,
                                  // justifyContent: 'space-between',
                                  width: 499,
                                  flexWrap: "wrap",
                                  borderRadius : "12px",
                                  padding : "17px 10px"
                                }}
                              >
                                <button
                                  onClick={() => {
                                    highlightHandler("Positive Comments");
                                  }}
                                  className="mr-2 ml-2 mt-2 positive_comment_btn"
                                >
                                  Positive Comments
                                </button>
                                <button
                                  onClick={() => {
                                    highlightHandler("Teaching Point");
                                  }}
                                  className="mr-2 ml-2 mt-2 teaching_point_btn"
                                >
                                  Teaching Point
                                </button>
                                <button
                                  onClick={() => {
                                    highlightHandler("Instructional Need");
                                  }}
                                  className="mr-2 ml-2 mt-2 instructional_need_btn"
                                >
                                  Instructional Need
                                </button>
                                <button
                                  onClick={() => {
                                    highlightHandler("Comments 1");
                                  }}
                                  className="mr-2 ml-2 mt-2 comment_one_btn"
                                >
                                  Comments 1
                                </button>
                                <button
                                  onClick={() => {
                                    highlightHandler("Comments 2");
                                  }}
                                  className="mr-2 ml-2 mt-2 comment_one_btn"
                                >
                                  Comments 2
                                </button>

                                <span
                                style={{
                                    position: "relative",
                                    left: "51%",
                                    top: "-33px",
                                    fontSize: "x-large",
                                    color: "rgba(112, 112, 112, 0.4901960784)",
                                    fontWeight: "700"
                                }}
                                role='button'
                                className='closeIcon'
                                aria-hidden="true"
                                // ref={setCloseButtonRef}
                            >
                                ×
                            </span>
                              </div>
                            </Card.Text>
                          </>
                        ) : (
                          <>
                            <Card.Text>
                             { console.log('session finalTranscribedAudio :>> ', sessionStorage.getItem("finalTranscribedAudio") , item?.tag_comment_text)}
                              <Form.Control
                                key={item.tag_comment_id || item.cc_tag_id || index}
                                name="tag_comment_text"
                                className="editTextArea"
                                as="textarea"
                                rows="7"
                                value={item?.cc_tag_title == "Audio Transcription" ? (sessionStorage?.getItem("finalTranscribedAudio") || item?.tag_comment_text) : item?.tag_comment_text} 
                                onChange={(e) => {
                                  console.log("OnChange event value: ", TagCommentText);
                                  setTagCommentText(e.target.value);
                                  OnChangeTextArea(e, (item?.tag_comment_id || item?.cc_tag_id), index);
                                  // OnChangeTextArea(e, (item?.tag_comment_id || item?.cc_tag_id), index);
                                }}
                                disabled={item.cc_tag_title === "Audio Transcription"}
                                // onMouseUp={(e) => {
                                // props.logH5Content(e)
                                // }}
                                // style={{fontSize: 17}}
                              />
                            </Card.Text>
                          </>
                        )}
                        {/* <Card.Text>
                                        <Form.Control
                                            name='tag_comment_text'
                                            className='editTextArea'
                                            as="textarea"
                                            rows="7"
                                            defaultValue={item.tag_comment_text}
                                            onChange={(e) => { OnChangeTextArea(e, index) }}
                                        // placeholder="Write comment note here…"
                                        />
                                    </Card.Text> */}
                        {/* -----comment card for edit---- */}
                      </Card.Body>
                    </Card>
                  </Col>
                )
              )
            )}
          {/* ------Edit comment card Section----- */}
        </Row>
        </>
      )}

      {/*-------------MODAL----------------*/}
      <Modal
        centered
        className="delete-modal-warning generateAudioModal transcribeAudioModalClass br-8"
        show={showTranscribeAudioModal}
        onHide={handleGenerateAudioAiModalClose}
      >
        <Modal.Header
          className="rubricsModalHeader generateAudioModalHeader pb-0 align-items-center"
          closeButton
        ></Modal.Header>
        <Modal.Body className="pb-0" style={{ position: "relative" }}>
          {editAudioTranscribeAndSummarize ? (
            <>
              <Form.Control
                as="textarea"
                rows={5}
                onChange={(e) => {
                  setAudioCommentText(e.target.value);
                }}
              >
                {audioTagCommentText}
              </Form.Control>
            </>
          ) : (
            <div
              style={{ position: "relative" }}
              id="div3"
              // onMouseUp={this.logH5Content}
            >
              {/* <TranscribeNote audioTranscribe={audioTagCommentText}/> */}
              <p className="transcribe_para">
                <div>
                  <div>
                    <p className="transcribe_para_pre">
                      {audioTagCommentText || ""}
                    </p>
                  </div>
                </div>
              </p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="justify-content-end w-100 transscribe-audio-modal-footer">
          {editAudioTranscribeAndSummarize ? (
            <>
              <div className="d-flex mt-4">
                <Button
                  className="deleteIcon"
                  onClick={() => {
                    // this.setState({
                    //     editAudioTranscribeAndSummarize: false,
                    //     audioTranscribe: this.props?.getTranscribeAudioSuccess?.data?.note_description
                    // })
                    setEditAudioTranscribeAndSummarize(false);
                    setAudioCommentText(
                      audioTagCommentText?.trim()?.length == 0
                        ? audioInitialCommentText
                        : audioTagCommentText
                    );
                    setShowTranscribeAudioModal(false);
                  }}
                  style={{
                    backgroundColor: "#fff",
                    color: "#000",
                    borderRadius: "8px",
                    minWidth: 40,
                  }}
                >
                  Cancel
                </Button>
                <div style={{ width: 20 }}></div>
                {/* <Button variant="success" onClick={() => {
                       this.findAndChangeTagContent('Audio Transcription')
                      setEditAudioTranscribeAndSummarize(false)
                    }} style={{ backgroundColor: '#fff', color : '#4AD245', textTransform: 'uppercase', borderRadius: '8px', minWidth: 40 }}>
                       Save
                    </Button> */}
                <Button
                  variant="primary"
                  className="summarize_btn text-white editNoteCommentCardBtn"
                  size="small"
                  onClick={() => {
                    saveTransciptionSummaryModal();
                  }}
                  disabled={
                    audioTagCommentText?.trim()?.length == 0 ? true : false
                  }
                  style={{
                    opacity:
                      audioTagCommentText?.trim()?.length == 0 ? "0.5" : "1",
                  }}
                >
                  Save
                </Button>
              </div>
            </>
          ) : (
            <>
              <Button
                variant="primary"
                className="summarize_btn text-white editNoteCommentCardBtn"
                startIcon={<EditIcon />}
                size="small"
                onClick={() => {
                  setEditAudioTranscribeAndSummarize(true);
                }}
              >
                Edit
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NoteCommentCard;
